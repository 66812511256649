import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useWeb3React } from '@web3-react/core/core/core';
import { Button } from '@pancakeswap/uikit';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const cardValues = {
  '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9, '10': 10,
  'J': 10, 'Q': 10, 'K': 10, 'A': 11
};

const cardSuits = ['heart', 'spade', 'diamond', 'club'];

const cards = Object.keys(cardValues);

const BlackJack = () => {
  const { account } = useWeb3React();
  const [playerScore, setPlayerScore] = useState<number>(0);
  const [dealerScore, setDealerScore] = useState<number>(0);
  const [playerHand, setPlayerHand] = useState<string[]>([]);
  const [dealerHand, setDealerHand] = useState<string[]>([]);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [deck, setDeck] = useState<string[]>([]);
  const [winner, setWinner] = useState<string | null>(null);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [canPlay, setCanPlay] = useState<boolean>(true); // 30 másodperces limithez
  const [userTotalPoints, setUserTotalPoints] = useState<number>(0); // Felhasználói pontok összesítése

  // Pontok lekérése Supabase-ból és összeszámolása
  const fetchUserPoints = useCallback(async () => {
    if (!account) return;

    const { data, error } = await supabase
      .from('blackjack_scores')
      .select('score') // Lekérjük az összes korábbi pontot
      .eq('account', account);

    if (error) {
      console.error('Error fetching scores:', error.message);
    } else if (data) {
      const totalPoints = data.reduce((acc, entry) => acc + calculatePoints(entry.score), 0);
      setUserTotalPoints(totalPoints); // Összes pontot elmentjük
    }
  }, [account]);

  const shuffleDeck = useCallback(() => {
    const shuffled = [...cards, ...cards, ...cards, ...cards].sort(() => Math.random() - 0.5);
    setDeck(shuffled);
  }, []);

  const calculateScore = useCallback((hand: string[]) => {
    let score = hand.reduce((acc, card) => acc + cardValues[card], 0);
    let aceCount = hand.filter(card => card === 'A').length;

    while (score > 21 && aceCount > 0) {
      score -= 10;
      aceCount--;
    }
    return score;
  }, []);

  // Pontszám kiszámítása a szabályok szerint
  const calculatePoints = (score: number): number => {
    if (score === 21) return 10;
    if (score === 20) return 7;
    if (score === 19) return 5;
    if (score === 17 || score === 18) return 3;
    if (score === 15 || score === 16) return 2;
    if (score > 0) return 1;
    return -1; // Ha 0 vagy kevesebb, -1 pont
  };

  const startNewGame = useCallback(() => {
    if (!canPlay) {
      alert('You must wait 30 seconds before starting a new game.');
      return;
    }

    if (deck.length < 4) {
      console.error('Not enough cards to start the game!');
      shuffleDeck();
      return;
    }
    const playerInitialHand = [deck[0], deck[2]];
    const dealerInitialHand = [deck[1], deck[3]];

    setPlayerHand(playerInitialHand);
    setDealerHand(dealerInitialHand);
    setPlayerScore(calculateScore(playerInitialHand));
    setDealerScore(calculateScore(dealerInitialHand.slice(0, 1)));
    setGameOver(false);
    setWinner(null);
    setDeck(deck.slice(4));
    setGameStarted(true);

    // 30 másodperces várakozás beállítása
    setCanPlay(false);
    setTimeout(() => setCanPlay(true), 30000);
  }, [deck, shuffleDeck, calculateScore, canPlay]);

  const playerDrawCard = () => {
    if (deck.length === 0) return;

    const card = deck[0];
    const newHand = [...playerHand, card];
    const newScore = calculateScore(newHand);

    setPlayerHand(newHand);
    setPlayerScore(newScore);
    setDeck(deck.slice(1));

    if (newScore > 21) {
      setGameOver(true);
      setWinner('Dealer');
      applyPenalty(); // Vesztés esetén levonás
    }
  };

  const dealerTurn = () => {
    let dealerNewScore = calculateScore(dealerHand);
    let dealerNewHand = [...dealerHand];

    while (dealerNewScore < 17 && deck.length > 0) {
      const card = deck[0];
      dealerNewHand = [...dealerNewHand, card];
      dealerNewScore = calculateScore(dealerNewHand);
      setDeck(deck.slice(1));
    }

    setDealerHand(dealerNewHand);
    setDealerScore(dealerNewScore);

    if (dealerNewScore > 21 || playerScore > dealerNewScore) {
      setWinner('Player');
      const pointsToAdd = calculatePoints(playerScore);
      setUserTotalPoints(prevPoints => prevPoints + pointsToAdd); // Hozzáadjuk az új pontot a korábbi összeghez
      saveScore(playerScore); // Csak nyertes pontszám mentése
    } else {
      setWinner('Dealer');
      applyPenalty(); // Dealer győzelem esetén pont levonása
    }

    setGameOver(true);
  };

  // Vesztés esetén -1 pont levonása
  const applyPenalty = () => {
    setUserTotalPoints(prevPoints => prevPoints - 1); // -1 pont levonása
    saveScore(-1); // -1 pont mentése Supabase-be
  };

  // Csak nyertes vagy vesztett pontszám mentése
  const saveScore = async (score: number) => {
    if (!account) return; // Ha nincs fiók, ne mentsünk

    const { error } = await supabase
      .from('blackjack_scores')
      .insert([{ account, score }]);

    if (error) {
      console.error('Error saving score:', error.message);
    }
  };

  const resetGame = useCallback(() => {
    if (!account) return;

    shuffleDeck();
    setGameStarted(false);
  }, [account, shuffleDeck]);

  const renderCard = (card: string) => {
    const suit = cardSuits[Math.floor(Math.random() * cardSuits.length)];
    return <div style={{ ...styles.card, ...styles[`suit-${suit}`] }}>{card}</div>;
  };

  useEffect(() => {
    // Felhasználói pontok lekérése oldalbetöltéskor
    fetchUserPoints();
  }, [fetchUserPoints]);

  return (
    <div style={styles.blackjackGame}>
      <h1>BlackJack</h1>
      <p>Account: {account || 'Connect your wallet'}</p>
      <p>Your Total Points: {userTotalPoints}</p> {/* Összesített pontok megjelenítése */}
      {account ? (
        <>
          {gameStarted ? (
            <>
              {/* Dealer fent */}
              <div style={styles.dealerContainer}>
                <h2>Dealer&apos;s Hand</h2>
                <div style={styles.cardContainer}>
                  <div style={styles.card}>{dealerHand[0]}</div>
                  {gameOver ? dealerHand.slice(1).map((card) => renderCard(card)) : <div style={styles.hiddenCard} />}
                </div>
                <p>Dealer Score: {gameOver ? dealerScore : '?'}</p>
              </div>
  
              {/* Player lent */}
              <div style={styles.playerContainer}>
                <h2>Your Hand</h2>
                <div style={styles.cardContainer}>
                  {playerHand.map((card) => renderCard(card))}
                </div>
                <p>Your Score: {playerScore}</p>
              </div>
  
              {winner ? <p>{winner} wins!</p> : (
                <div style={styles.buttonContainer}>
                  <Button onClick={playerDrawCard} disabled={gameOver}>Draw Card</Button>
                  <Button onClick={dealerTurn} disabled={gameOver || playerScore > 21}>Stand</Button>
                </div>
              )}
  
              {/* Reset gomb csak akkor jelenik meg, ha a játék már elindult */}
              <Button className="reset" onClick={resetGame}>Reset</Button>
            </>
          ) : (
            <Button onClick={startNewGame} disabled={!canPlay}>New Game</Button>
          )}
        </>
      ) : (
        <p>Please connect your wallet to start the game.</p>
      )}
    </div>
  );  
};

// Inline stílusok TypeScript-ben
const styles: { [key: string]: React.CSSProperties } = {
  blackjackGame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#222',
    color: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
    maxWidth: '600px',
    margin: '0 auto',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  card: {
    width: '80px',
    height: '120px',
    backgroundColor: 'white',
    color: 'black',
    border: '2px solid black',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  'suit-heart': {
    color: 'red',
  },
  'suit-diamond': {
    color: 'red',
  },
  'suit-spade': {
    color: 'black',
  },
  'suit-club': {
    color: 'black',
  },
  hiddenCard: {
    width: '80px',
    height: '120px',
    backgroundColor: 'gray',
    borderRadius: '5px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '20px',
  },
};

export default BlackJack;
