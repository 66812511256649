import { Button,Box,Text } from "@pancakeswap/uikit";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  padding: 20px; 
  max-width: 800px; 
  margin: 20px auto;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;
const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 1px #62d5a3, 0 0 2px #62d5a3, 0 0 2.5px #62d5a3, 0 0 5px #62d5a3;
  }
  50% {
    text-shadow: 0 0 2px #62d5a3, 0 0 4px #62d5a3, 0 0 4px #62d5a3, 0 0 6px #62d5a3;
  }
  100% {
    text-shadow: 0 0 1px #62d5a3, 0 0 2px #62d5a3, 0 0 2.5px #62d5a3, 0 0 5px #62d5a3;
  }
`;
const Title = styled(Text)`
  text-align: center;
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
  color: #fff;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
`;

const TokenList = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenCard = styled.div<{ isSelected: boolean }>`
  margin: 10px 0;  
  padding: 15px;
  background: ${({ theme, isSelected }) => isSelected ? '#282c34' : theme.colors.background};
  border-radius: 12px;
  box-shadow: ${({ isSelected }) => isSelected ? '0 12px 24px rgba(0, 0, 0, 0.4)' : '0 6px 12px rgba(0, 0, 0, 0.2)'};
  display: flex;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    background: #333;
  }
`;

const TokenImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;

const TokenDetails = styled.div`
  flex: 1;
`;

const TokenLabel = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
`;

const TokenAddress = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0;
`;

const TokenValue = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  margin: 5px 0 0;
`;

const TotalValue = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.text};
`;

const StyledButton = styled(Button)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.2s, transform 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    transform: scale(1.05);
  }
`;

const Arrow = styled.span<{ direction: 'up' | 'down' }>`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-${({ direction }) => direction}: 6px solid ${({ theme }) => theme.colors.background};
  margin-left: 8px;
`;

export { Container, Arrow, StyledButton,TotalValue, TokenValue, TokenAddress, TokenCard, TokenDetails, TokenList, TokenLabel, TokenImage, Title };
