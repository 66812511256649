import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import { Button, Input } from '@pancakeswap/uikit';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// Felhasználói avatar
export const UserAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

// Felhasználói elem a toplistában (név és üzenetszám)
export const UserItem = styled.li`
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: black;
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  flex-direction: column; /* Mobilbarát kétsoros elrendezés */
  text-align: center;

  &:hover {
    transform: translateY(-5px);
  }

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
  }
`;

// Felhasználói név
export const UserName = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: gold; /* Azonos fény és szín, mint a TopListTitle */
  margin-right: 10px;
`;

// Az üzenetszám stílusa
export const MessageCount = styled.span`
  font-size: 14px;
  color: white;
`;

export const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

export const TopListTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: white; /* Szín a címhez */
`;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

const Toplist = () => {
  const [topList, setTopList] = useState([]);
  const [names, setNames] = useState({});
  const [userNumbers, setUserNumbers] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchTopList = async () => {
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .order('timestamp', { ascending: true });

      if (!error) {
        const userMessageCount = new Map();
        data.forEach((msg) => {
          if (msg.text && msg.text.trim() !== '') {
            userMessageCount.set(msg.sender, (userMessageCount.get(msg.sender) || 0) + 1);
          }
        });

        const sortedTopList = Array.from(userMessageCount.entries()).sort((a, b) => b[1] - a[1]);
        setTopList(sortedTopList.slice(0, 50)); // Csak a top 50 usert mutatjuk
      }
    };

    fetchTopList();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const newNames = {};
      const newNumbers = {};
      await Promise.all(
        topList.map(async ([user]) => {
          const { data: existingData, error } = await supabase
            .from('user_data')
            .select('*')
            .eq('account', user)
            .single();

          if (!error && existingData) {
            newNames[user] = existingData.name;
            newNumbers[user] = existingData.number;
          } else {
            newNames[user] = user; // Ha nincs név, az account cím egy részét mutatjuk
          }
        })
      );

      setNames(newNames);
      setUserNumbers(newNumbers);
    };

    if (topList.length > 0) {
      fetchUserData();
    }
  }, [topList]);

  const getUserImage = (user) => {
    const userNumber = userNumbers[user];

    if (userNumber) {
      let formattedNumber;
      if (userNumber < 10) {
        formattedNumber = userNumber.toString().padStart(2, '0');
      } else {
        formattedNumber = userNumber.toString().padStart(3, '0');
      }
      return `images/pixel/${formattedNumber}.jpg`;
    }

    return 'images/pixel/200.png'; // Alapértelmezett kép, ha nincs szám
  };

  const filteredTopList = topList.filter(([user]) => {
    const userName = names[user]?.toLowerCase() || '';
    return userName.includes(searchQuery.toLowerCase()) || user.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Container>
      {/* Vissza nyíl a chatre */}
      <Link to="/chat">
  <Button style={{ backgroundColor: 'gold', color: 'black' }}>&lt; Back to Chat</Button>
</Link>

      <Container>
        <TopListTitle>Top List</TopListTitle>

        {/* Kereső mező */}
        <StyledInput
          placeholder="Search by name or address"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <ul>
          {filteredTopList.map(([user, count], index) => (
            <UserItem key={user}>
              <UserAvatar src={getUserImage(user)} alt={`${names[user] || user}'s avatar`} />
              <UserName>{names[user] || `${user.substring(0, 5)}...${user.substring(user.length - 5)}`}</UserName>
              <MessageCount>{count} messages</MessageCount>
            </UserItem>
          ))}
        </ul>
      </Container>
    </Container>
  );
};

export default Toplist;
