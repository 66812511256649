import styled from 'styled-components';
import { Text } from '@pancakeswap/uikit'; // Import PancakeSwap's Text component

export const Container = styled.div`
  padding: 20px;
  background: linear-gradient(135deg, #000000, #1a1a1a);
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
`;

export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05); /* Semi-transparent background */
  border-radius: 10px;
  padding: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  gap: 16px;
  width: 100%;
  max-width: 600px; /* Maximum width for a more compact look */
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.8); /* Darker, more dramatic background */
  font-size: 16px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 100%;
  max-width: 500px;

  &:hover {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const UserAvatar = styled.img`
  width: 50px; /* Larger avatar */
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #ffcc00; /* Gold border around avatar */
`;

export const UserName = styled(Text)`
  font-size: 15px; /* Slightly larger font size */
  font-weight: bold;
  color: #ffcc00; /* Gold text color */
  text-align: center;
  margin-right: 20px; /* More space between name and status */
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  width: 100%;
`;

export const BackButton = styled.button`
  background: linear-gradient(135deg, #ff9900, #ff6600);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(255, 153, 0, 0.5);

  &:hover {
    background-color: #ff3300;
    box-shadow: 0 6px 20px rgba(255, 51, 0, 0.7);
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  color: #ffcc00; /* Gold color for title */
  margin-left: 20px;
  text-shadow: 2px 2px 10px rgba(255, 204, 0, 0.8); /* Glowing effect */
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    border-color: #ffcc00; /* Gold border on focus */
    box-shadow: 0 0 10px rgba(255, 204, 0, 0.5);
  }
`;
